import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import PageHeader from "../components/common/PageHeader"
import RecipeCard from "../components/common/RecipeCard"

import Layout from "../components/layout"
import RecipeGrid from "../components/recipes/RecipeGrid"
import Seo from "../components/seo"

const Wrapper = styled.div``

export const query = graphql`
  query RecipesQuery {
    contentfulRecipes {
      recipes {
        slug
        recipeName
        alcoholic
        customerFavorite
        recipeDescription {
          raw
        }
        recipeImage {
          gatsbyImage(width: 560, placeholder: BLURRED)
        }
      }
    }
  }
`

function divideArray(objects: Array<any>, K: number, N: number) {
  let ans: any = []
  let temp: any = []
  for (let i = 0; i < N; i++) {
    temp.push(objects[i])
    if ((i + 1) % K == 0) {
      ans.push(temp)
      temp = []
    }
  }

  if (temp.length != 0) {
    ans.push(temp)
  }

  return ans
}

export function Head() {
  return <Seo title={"Recipes"} />
}

export default function Recipes({ data }) {
  const recipes = data.contentfulRecipes.recipes
  const dividedGrid = divideArray(recipes, 9, recipes.length)

  return (
    <Layout>
      <PageHeader
        header="Recipes"
        subheader="Zero-proof Drømme is best served in beautiful glassware, garnished with friends and really good music. Skål!"
        body={null}
        cta="View Recipes"
        onCtaClick={() => window.scrollBy(0, 265)}
      />

      {dividedGrid.map(grid => (
        <RecipeGrid>
          {grid.map(recipe => (
            <RecipeCard recipe={recipe} inline />
          ))}
        </RecipeGrid>
      ))}
    </Layout>
  )
}
